import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import { TOAST_TYPE } from 'components/_v2/_common/toast/JDToast';
import { LazyImageLogo } from 'components/common/LazyImage';
import RoutePaths from 'consts/RoutePaths';
import { MatchApplyStatusType, MatchResponseType } from 'consts/_v2/match/MatchType';
import { lineClampBox } from 'consts/style/mixins';
import useToast from 'hooks/useToast';
import { MessageAccessPath } from 'interfaces/_v2/log/ILogRqRs';
import { IMatchingStatusDto } from 'interfaces/_v2/matchApply/IMatchingStatusListRs';
import { inject, observer } from 'mobx-react';
import MatchMessageStore from 'models/MatchMessageStore';
import MatchApplyStatusModel from 'models/_v2/MatchApplyStatusModel';
import { injectStore } from 'models/store';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { DateFormatYYYYMMDDWithDot, getDDayString, str2DateTime } from 'utils/DateUtils';

const Frame = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 24px 16px;
    
    .left-area {
        display: flex;
        align-items: center;
    }

    .logo {
        width: 48px;
        height: 48px;
        border-radius: 8px;
        border: 1px solid ${colors.CG_80_20};
        overflow: hidden;
        flex:none;
        .img-fallback {
            width: 48px;
            height: 48px;
        }
    }
    
    &[aria-disabled=true] {
        opacity: 0.5;
        background-color: ${colors.CG_30};
    }

    .like-tooltip {
        padding: 14px;
        position: absolute;
        top: -30px;
        right: 16px;
        width: fit-content;
        height: fit-content;
        font: ${Fonts.B3_Medium};
        color: ${colors.CG_30};
        background-color: ${colors.CG_70};
        border-radius: 4px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.12);

        >b {
          font: ${Fonts.B3_Bold};
        }
    }
`;

const CardContent = styled.div<{ activeStatus: boolean}>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    .title-frame {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        
    }
   
    .companyName {
        padding-right: 32px;
        font: ${Fonts.B1_Bold};
        color: ${colors.JOBDA_BLACK};
        ${lineClampBox(1)}; 
    }

    .position-title {
        padding-right: 32px;
        font: ${Fonts.B2_Medium};
        color: ${colors.CG_70};
        ${lineClampBox(1)};
        margin-bottom: 8px;
    }

    .d_day_frame{
        width: fit-content;
        margin-right: 16px;
        padding: 4px 6px;
        border-radius: 4px;
        border: 1px solid ${colors.CG_50};
        background-color:${colors.JOBDA_WHITE};
        span{
            display: block;
            font: ${Fonts.B4_Medium};
            color:${colors.CG_90};
        }

        &[aria-selected=true] {
          background-color:${colors.JOBDA_BLACK};

          >span {
            color: ${colors.WHITE_100};
          }
          
        }
    }

    .desc {
        display: flex;
        align-items: center;

        .date {
            font: ${Fonts.B3_Medium};
            color: ${colors.CG_70};
            margin-right: 16px;
        }

        .status-type {
            font: ${Fonts.B3_Bold};
            color: ${(props) => (props.activeStatus ? `${colors.G_200}` : colors.JOBDA_BLACK)};
        }

    }
`;

const getStatusMessage = (statusType: MatchApplyStatusType, responseType?: MatchResponseType): string => {
  if (statusType === MatchApplyStatusType.PROFILE_OPEN) {
    return '프로필 열람';
  } if (statusType === MatchApplyStatusType.RECOMMEND) {
    return '추천됨';
  } if (responseType === MatchResponseType.UNANSWERED) {
    return '제안 미응답';
  } if (statusType === MatchApplyStatusType.OFFER) {
    if (responseType === MatchResponseType.ACCEPTED) {
      return '수락 완료';
    } if (responseType === MatchResponseType.REJECTED) {
      return '제안 거절';
    }
    return '제안 내용 확인';
  } if (statusType === MatchApplyStatusType.OFFER_CLOSED) {
    return '제안 마감';
  }
  return '';
};

const getStatusActive = (statusType: MatchApplyStatusType, responseType?: MatchResponseType) => statusType === MatchApplyStatusType.OFFER && (!responseType || responseType === MatchResponseType.ACCEPTED);

interface IMatchingListCardProps {
  matchItem: IMatchingStatusDto;
  matchMessageStore?: MatchMessageStore;
  matchApplyStatusModel?: MatchApplyStatusModel;
  firstToolTip: boolean;
}

const MatchingListCard: FC<IMatchingListCardProps> = ({ matchItem, matchMessageStore = new MatchMessageStore(), matchApplyStatusModel = new MatchApplyStatusModel(), firstToolTip }) => {
  const history = useHistory();
  const cardDisabled = matchItem.statusType === MatchApplyStatusType.OFFER_CLOSED || matchItem.responseType === MatchResponseType.REJECTED || matchItem.responseType === MatchResponseType.UNANSWERED;
  const [like, setLike] = useState<boolean>(false);
  const { setToastObject } = useToast();
  const [showTooltip, setShowTooltip] = useState(firstToolTip);
  const dDay = matchItem.decisionDueDateTime ? getDDayString(str2DateTime(matchItem.decisionDueDateTime)) : '';

  useEffect(() => {
    if (firstToolTip) {
      const timer = setTimeout(() => {
        setShowTooltip(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
    return () => {};
  }, [firstToolTip]);

  useEffect(() => {
    if (matchItem.likeYn !== undefined)
      setLike(matchItem.likeYn);
  }, [matchItem.likeYn]);

  const handleLikeCompany = useCallback(async (e) => {
    e.stopPropagation();
    if (cardDisabled) return;
    try {
      if (like) {
        await matchApplyStatusModel.unlikePosition(matchItem.positionSn);
        setToastObject({ isOpen: true, type: TOAST_TYPE.INFO, message: '관심 표현을 취소했어요.' });
      } else {
        await matchApplyStatusModel.likePosition(matchItem.positionSn);
        setToastObject({ isOpen: true, type: TOAST_TYPE.INFO, message: '인사담당자에게 관심 표현이 전달되었어요.' });
      }
      setLike(!like);
    } catch (error) {
      console.error(error);
      setToastObject({ isOpen: true, type: TOAST_TYPE.ERROR, message: '문제가 발생했습니다. 다시 시도해 주세요.' });
    }
  }, [like, matchApplyStatusModel, matchItem.matchingSn, setToastObject]);

  const handleClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (!cardDisabled) {
      if (matchItem.statusType === MatchApplyStatusType.OFFER) {
        matchMessageStore.messageAccessPath = MessageAccessPath.MATCHING_CARD;
        matchMessageStore.open(matchItem.positionSn);
        matchApplyStatusModel.readMatchCard(matchItem.matchingSn);
      } else {
        history.push(RoutePaths.position_jd(matchItem.positionSn));
      }
    }
  }, [cardDisabled, matchItem, matchMessageStore, matchApplyStatusModel]);

  const handleCompanyClick = useCallback((e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
    e.stopPropagation();
    if (cardDisabled) return;
    history.push(RoutePaths.company_details(matchItem.companySn));
  }, [cardDisabled, history, matchItem.companySn]);

  return (
    <Frame aria-disabled={cardDisabled} onClick={handleClick}>
      <div className='left-area'>
        <button disabled={cardDisabled} className='logo' onClick={handleCompanyClick}>
          <LazyImageLogo src={matchItem.companyLogoUrl} />
        </button>
        <SpacingBlock size={16} />
        <CardContent activeStatus={getStatusActive(matchItem.statusType, matchItem.responseType)}>
          <div className='title-frame'>
            <div role='button' className='companyName'>
              {matchItem.companyName}
            </div>
          </div>
          <div role='button' className='position-title'>
            {matchItem.positionTitle}
          </div>
          <div className='desc'>
            {
              matchItem.statusType === MatchApplyStatusType.OFFER && dDay && !matchItem.responseType
                ? (
                  <div className='d_day_frame' aria-selected={dDay === 'D-day'}>
                    <span>{matchItem.decisionDueDateTime && `응답 기한 : ${dDay}`}</span>
                  </div>
                )
                : (
                  <div className='date'>{DateFormatYYYYMMDDWithDot(matchItem.matchingStatusLastModifiedDateTime)}</div>
                )
            }
            <div className='status-type'>{getStatusMessage(matchItem.statusType, matchItem.responseType)}</div>
          </div>
        </CardContent>
      </div>
      {(matchItem.statusType === MatchApplyStatusType.PROFILE_OPEN || matchItem.statusType === MatchApplyStatusType.RECOMMEND) && (
        like ? <Icon name='heart-filled' size={32} color={colors.ERROR} onClick={handleLikeCompany} />
          : <Icon name='heart' size={32} color={colors.JOBDA_BLACK} onClick={handleLikeCompany} />
      )}
      {showTooltip && (
      <div className='like-tooltip'>포지션에 <b>관심 표현하고</b><br />제안받을 확률 올려보세요!</div>
      )}
      {
        (matchItem.statusType === MatchApplyStatusType.OFFER && matchItem.responseType === MatchResponseType.ACCEPTED) && (
        <Icon name='arrow-right' size={32} color={colors.JOBDA_BLACK} />
        )
      }
    </Frame>
  );
};

export default inject(injectStore.matchMessageStore, injectStore.matchApplyStatusModel)(observer(MatchingListCard));
