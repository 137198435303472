/* eslint-disable class-methods-use-this */
import URIs from 'consts/URIs';
import { InsightResumeLogRq } from 'interfaces/_v2/insight/IInsightResumeRqRs';
import { INotificationViewLogSaveRq, IPushNotificationSendLogSaveRq } from 'interfaces/_v2/log/ILogRqRs';
import { request } from 'utils/request';

export default interface LogRepo {
    // 지원서 로그 저장
    saveLog(rq:InsightResumeLogRq): Promise<boolean>;

    saveNotificationLogSendPush(rp:IPushNotificationSendLogSaveRq):Promise<boolean>;

    saveNotificationLogView(rq:INotificationViewLogSaveRq):Promise<boolean>;

}

export class RemoteLogRepo implements LogRepo {
  saveLog(rq:InsightResumeLogRq): Promise<boolean> {
    return request({
      method: 'post',
      url: URIs.post_v1_logs,
      data: rq,
    });
  }

  saveNotificationLogSendPush(rq:IPushNotificationSendLogSaveRq): Promise<boolean> {
    return request({
      method: 'post',
      url: URIs.post_v2_notification_logs_send_push,
      data: rq,
    });
  }

  saveNotificationLogView(rq:INotificationViewLogSaveRq): Promise<boolean> {
    return request({
      method: 'post',
      url: URIs.post_v2_notification_logs_view,
      data: rq,
    });
  }
}
