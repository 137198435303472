/* eslint-disable camelcase */
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import CustomInfiniteScroll from 'components/CustomInfiniteScroll';
import JDPopover from 'components/_v2/_common/popover/JDPopover';
import MatchApplyBlurFrame from 'components/_v2/matchApply/matchApplyStatus/MatchApplyBlurFrame';
import MatchingListCard from 'components/_v2/matchApply/matchApplyStatus/MatchingListCard';
import { Divider1G40 } from 'components/divider/Divider';
import MatchDashBoardMessageModal from 'components/jobMatch/dashboard/MatchDashBoardMessageModal';
import RoutePaths from 'consts/RoutePaths';
import { MatchApplyStatusType } from 'consts/_v2/match/MatchType';
import matchItemDummy from 'consts/_v2/match/matchItemDummy';
import useQueryParams from 'hooks/useQueryParams';
import IMatchApplyStatusProps from 'interfaces/_v2/matchApply/IMatchApplyStatusProps';
import { IMatchingStatusDto } from 'interfaces/_v2/matchApply/IMatchingStatusListRs';
import { inject, observer } from 'mobx-react';
import MatchMessageStore from 'models/MatchMessageStore';
import MatchApplyStatusModel from 'models/_v2/MatchApplyStatusModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import ImgEmptyJoy from 'assets/_v2/positionList/img_character_joy_empty.png';
import { MessageAccessPath } from 'interfaces/_v2/log/ILogRqRs';

const HEAD_FRAME_HEIGHT = 329;

const Frame = styled.div``;

const MatchApplyStatusTab = styled.div`
    padding: 0 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;

    .tab-box {   
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 56px;
        padding: 8px;
        border-radius: 4px;
        background-color: ${colors.WHITE_100};
        font: ${Fonts.B4_Medium};
        color: ${colors.CG_60};

        .count {
          position: relative;
          font: ${Fonts.H5_Bold};
          color: ${colors.CG_60};
        }

        .count.red-dot:after {
            content: '';
            width: 6px;
            height: 6px;
            background: ${colors.ERROR};
            position: absolute;
            top: 0; 
            right: -8px; 
            border-radius: 50%;
        }


        &[aria-current=true] { 
            background-color: ${colors.CG_30};
            color: ${colors.JOBDA_BLACK};

            .count {
                font: ${Fonts.H5_Bold};
                color: ${colors.JOBDA_BLACK};
            }
        }
    }
`;

const EmptyFrame = styled.div`
  padding: 64px 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.CG_30};
  .img-joy{
    width: 80px;
    height: 80px;
    
  }
`;

const MatchApplyStatusTipTextFrame = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px 16px;
    height: 124px;

    .title-frame {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
      .rate-chip {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font: ${Fonts.B3_Bold};
        padding: 8px 16px;
        border: 1px solid ${colors.CG_50};
        border-radius: 48px;

        .rate {
            color: ${colors.G_200};
            margin: 0 4px;
        }

        .popover-frame{
          font: ${Fonts.B3_Medium};
          color: ${colors.CG_30};
        }
      }
    }
    .title {
        display: flex;
        align-items: center;
        font: ${Fonts.H5_Bold};
        color: ${colors.JOBDA_BLACK};
      
        margin-bottom: 4px;

        >span {
          margin-left: 4px;
        }
        
        
    }

    .sub {
        font: ${Fonts.B3_Medium_P};
        color: ${colors.CG_70};
        white-space: pre-line;
    }
`;

export enum MatchApplyStatusTabType {
  RECOMMEND = 'RECOMMEND',
  MATCH_OFFER = 'MATCH_OFFER',
  MATCH_INTERVIEW_OFFER = 'MATCH_INTERVIEW_OFFER',
}

interface MatchApplyStatusTipTextProps {
  icon: React.ReactNode;
  title: string;
  sub: string;
  responseRate?: number | null;
}

const MatchApplyStatusTipText: React.FC<MatchApplyStatusTipTextProps> = ({ icon, title, sub, responseRate }) => (
  <MatchApplyStatusTipTextFrame>
    <div className='title-frame'>
      <div className='title'>{icon} <span>{title}</span> </div>
      {responseRate !== null
      && (
      <div className='rate-chip'>
        응답률<span className='rate'>{responseRate}%</span>
        <JDPopover
          useOutsideClick
          position='non_pony_under_right'
          popoverWidth={180}
          anchorIcon={<Icon name='information' size={20} color={colors.CG_70} />}
        >
          <div className='popover-frame'>
            응답률이 50% 이상인 지원자는 더 많은 제안을 받고 있어요.
          </div>
        </JDPopover>

      </div>
      )}
    </div>
    <div className='sub'>{sub}</div>
  </MatchApplyStatusTipTextFrame>
);

const MatchApplyStatusBoard: FC<IMatchApplyStatusProps> = ({ matchApplyStatusModel = new MatchApplyStatusModel(), matchMessageStore = new MatchMessageStore() }) => {
  const [currentTab, setCurrentTab] = useState<MatchApplyStatusTabType>(MatchApplyStatusTabType.MATCH_OFFER);
  const history = useHistory();
  const { tab, sn } = useQueryParams();

  // 탭 제거에 따른 임시 주석처리들
  // const queryTab = Object.values(MatchApplyStatusTabType).includes(tab as MatchApplyStatusTabType)
  //   ? tab as MatchApplyStatusTabType
  //   : MatchApplyStatusTabType.MATCH_OFFER;

  const tabs = [
    { type: MatchApplyStatusTabType.RECOMMEND, count: matchApplyStatusModel.matchStatus?.recommendCount || 0, new: matchApplyStatusModel.matchStatus?.newRecommendYn, label: '추천됨' },
    { type: MatchApplyStatusTabType.MATCH_OFFER, count: matchApplyStatusModel.matchStatus?.offerCount, new: matchApplyStatusModel.matchStatus?.newOfferYn, label: '입사지원 제안' },
    { type: MatchApplyStatusTabType.MATCH_INTERVIEW_OFFER, count: matchApplyStatusModel.matchStatus?.interviewOfferCount, new: matchApplyStatusModel.matchStatus?.newInterviewOfferOpenYn, label: '면접 제안' },
  ];

  useEffect(() => {
    (async () => {
      await matchApplyStatusModel.loadMatchStatus();
    })();
  }, []);

  useEffect(() => {
    if (sn) {
      matchMessageStore.messageAccessPath = MessageAccessPath.MATCHING_CARD;
      openMessageModal(Number(sn));
    }
  }, [sn]);

  // useEffect(() => {
  //   if (queryTab) setCurrentTab(queryTab);
  // }, [queryTab]);

  useEffect(() => {
    matchApplyStatusModel.matching = null;
    updateList();
  }, [currentTab]);

  const openMessageModal = async (matchingSn: number) => {
    matchMessageStore.open(matchingSn);
    await matchApplyStatusModel.loadMatchStatus();
  };
  const updateList = async () => {
    await matchApplyStatusModel.loadMatchList(currentTab);
    const hasUnreadItems = matchApplyStatusModel.matching?.matchingList.some((item) => !item.readYn);
    if (hasUnreadItems) readMatchTab(currentTab);
  };

  const readMatchTab = async (readTab: MatchApplyStatusTabType) => {
    switch (readTab) {
      case MatchApplyStatusTabType.RECOMMEND:
        if (!matchApplyStatusModel.matchStatus?.newRecommendYn) return;
        break;
      case MatchApplyStatusTabType.MATCH_OFFER:
        if (!matchApplyStatusModel.matchStatus?.newOfferYn) return;
        break;
      case MatchApplyStatusTabType.MATCH_INTERVIEW_OFFER:
        if (!matchApplyStatusModel.matchStatus?.newInterviewOfferOpenYn) return;
        break;
      default:
        break;
    }
    await matchApplyStatusModel.readMatchCards(readTab);
    await matchApplyStatusModel.loadMatchStatus();
    await matchApplyStatusModel.loadMatchList(currentTab);
  };

  // const handleTabClick = async (tabItem: any) => {
  //   history.replace(RoutePaths.match_apply_status_tab(tabItem.type));
  //   setCurrentTab(tabItem.type);
  // };

  let isFirstDesiredStatusFound = false;

  const hasAnyUnlikedItem = matchApplyStatusModel.matching?.matchingList.some((matchItem) => !matchItem.likeYn);
  const renderedList = matchApplyStatusModel.matching?.matchingList.map((matchItem: IMatchingStatusDto) => {
    const isDesiredStatus = (matchItem.statusType === MatchApplyStatusType.PROFILE_OPEN || matchItem.statusType === MatchApplyStatusType.RECOMMEND);
    const shouldShowTooltip: boolean = !!(isDesiredStatus && !isFirstDesiredStatusFound && hasAnyUnlikedItem && !matchItem.likeYn);
    if (shouldShowTooltip) {
      isFirstDesiredStatusFound = true;
    }

    return (
      <>
        <MatchingListCard key={matchItem.matchingSn} matchItem={matchItem} firstToolTip={shouldShowTooltip} />
        <Divider1G40 />
      </>
    );
  });

  const loadInfiniteScroll = async () => {
    await matchApplyStatusModel.lazeLoadMatchList(currentTab);
  };

  const renderMatchApplyStatusTipText = () => {
    let icon = <></>;
    let title = '';
    let sub = '';
    // switch (currentTab) {
    //   case MatchApplyStatusTabType.RECOMMEND:
    //     icon = <Icon name='verified' size={32} />;
    //     title = '추천된 포지션';
    //     sub = '잡다의 전문 큐레이터가 나의 프로필과 역량 정보를 확인하고,\n최적의 포지션에 추천한 목록입니다.';
    //     break;
    //   case MatchApplyStatusTabType.MATCH_OFFER:
    //     icon = <Icon name='folder' size={32} />;
    //     title = '입사지원 제안받은 포지션';
    //     sub = '제안에 응답하면 10일 안에 다음 전형에 대한.\n안내를 받으실 수 있어요.';
    //     break;
    //   case MatchApplyStatusTabType.MATCH_INTERVIEW_OFFER:
    //     icon = <Icon name='work' size={32} />;
    //     title = '제안 받은 포지션';
    //     sub = '인사담당자가 직접 보낸 제안 포지션 목록입니다.\n응답률이 높을수록 더 좋은 제안받을 수 있어요.';
    //     break;
    //   default:
    // }
    icon = <Icon name='folder' size={32} />;
    title = '입사지원 제안받은 포지션';
    sub = '제안에 응답하면 10일 안에 다음 전형에 대한.\n안내를 받으실 수 있어요.';
    return <MatchApplyStatusTipText icon={icon} title={title} sub={sub} responseRate={matchApplyStatusModel.responseRate || null} />;
  };

  return (
    <>
      { renderMatchApplyStatusTipText() }
      <Frame>
        { matchApplyStatusModel.matching?.matchingList.length === 0 ? (
          <EmptyFrame>
            <img className='img-joy' src={ImgEmptyJoy} alt='joy' />
            <MatchApplyBlurFrame />
          </EmptyFrame>
        ) : (
          <>
            <SpacingBlock size={16} vertical />
            <Divider1G40 />
            <CustomInfiniteScroll onInfinite={loadInfiniteScroll} threshold={150}>
              { renderedList }
            </CustomInfiniteScroll>
          </>
        )}
        {/* <MatchApplyStatusTab>
          { tabs.map((tabItem) => (
            <button
              key={tabItem.type}
              className='tab-box'
              onClick={() => handleTabClick(tabItem)}
              aria-current={currentTab === tabItem.type}
            >
              <span className={`count ${tabItem.new ? 'red-dot' : ''}`}>
                {tabItem.count}
              </span>{tabItem.label}
            </button>
          ))}
        </MatchApplyStatusTab> */}

      </Frame>
      <MatchDashBoardMessageModal />
    </>
  );
};

export default inject(injectStore.matchApplyStatusModel, injectStore.matchMessageStore)(observer(MatchApplyStatusBoard));
