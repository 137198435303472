/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import { MediumType, MessageAccessPath } from 'interfaces/_v2/log/ILogRqRs';
import MobileStore from 'store/mobileStore';

export const determineMediumType = (mediumType?:string, utm_source?:string) => {
  if (mediumType && mediumType === 'PUSH') {
    return MediumType.PUSH;
  }
  if (utm_source) {
    if (utm_source === 'kakaotalk') {
      return MediumType.KAKAOTALK;
    }

    if (utm_source === 'EMAIL') {
      return MediumType.EMAIL;
    }

    if (utm_source === 'SMS') {
      return MediumType.SMS;
    }
  }

  // mediumType과 utm_source가 없고 브라우저 타입이 APP인 경우
  if (!mediumType && !utm_source && MobileStore.isMobile) {
    return MediumType.APP;
  }

  // mediumType과 utm_source가 없고 브라우저 타입이 WEB인 경우
  if (!mediumType && !utm_source && !MobileStore.isMobile) {
    return MediumType.MOBILE_BROWSER;
  }

  return MediumType.UNKNOWN;
  // 조건에 맞지 않는 경우 UNKNOWN 환
};

export const determinesAccessPath = (mediumType?:MediumType, path?:MessageAccessPath) => {
  if (mediumType === MediumType.APP || mediumType === MediumType.MOBILE_BROWSER) {
    return path;
  }
  if (mediumType === MediumType.PUSH || mediumType === MediumType.EMAIL || mediumType === MediumType.SMS) {
    return MessageAccessPath.AUTO;
  }
  return MessageAccessPath.NONE;
};
