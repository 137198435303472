import URIs from 'consts/URIs';
import IMatchingMessageListRs, { IMatchingMessageRs } from 'interfaces/rqrs/IMatchingMessageListRs';
import { IAccProgressCompleteDto } from 'interfaces/_v2/acc/IAccProgressCompleteRs';
import { IPaccProgressCompleteDto } from 'interfaces/_v2/phs/pacc/IPaccProgressCompleteRs';
import { IPstProgressCompleteDto } from 'interfaces/_v2/phs/pst/IPstProgressCompleteRs';
import { action, observable } from 'mobx';
import request from 'utils/request';
import { IMatchAdjustRq, IMatchingRejectRq } from 'interfaces/_v2/matchApply/IMatchingRejectRq';
import { MessageAccessPath } from 'interfaces/_v2/log/ILogRqRs';

export default class MatchMessageStore {
  @observable isOpen: boolean = false;
  @observable positionSn: number = -1;
  @observable messagesDto: IMatchingMessageListRs | null = null;
  @observable message: IMatchingMessageRs | null = null;
  @observable balloonModalView: boolean = false;
  @observable accProgressList: IAccProgressCompleteDto[] | null = null;
  @observable pstProgressList: IPstProgressCompleteDto[] | null = null;
  @observable paccProgressList: IPaccProgressCompleteDto[] | null = null;
  @observable selectedApplySn: number = 0;
  @observable messageAccessPath:MessageAccessPath = MessageAccessPath.NONE;

  @action
  init = () => {
    this.balloonModalView = false;
    this.message = null;
    this.messagesDto = null;
  }

  @action
  open = (positionSn: number) => {
    this.positionSn = positionSn;
    this.isOpen = true;
  }

  @action
  close = () => {
    this.positionSn = -1;
    this.isOpen = false;
    this.messagesDto = null;
    this.message = null;
  }

  @action
  openBalloonModal = () => {
    this.balloonModalView = true;
  }

  @action
  closeBalloonModal = () => {
    this.balloonModalView = false;
  }

  @action
  loadAccProgressList = async () => {
    try {
      this.accProgressList = (await request<{resultRecords : IAccProgressCompleteDto[]}>({
        method: 'get',
        url: URIs.get_acc_results_list,
      })).resultRecords;
    } catch (e) {
      console.error(e);
    }
  }

  @action
  loadPaccProgressList = async () => {
    try {
      this.paccProgressList = (await request<{resultRecords : IPaccProgressCompleteDto[]}>({
        method: 'get',
        url: URIs.get_pacc_results_list,
      })).resultRecords;
    } catch (e) {
      console.error(e);
    }
  }

  @action
  loadPstProgressList = async () => {
    try {
      this.pstProgressList = (await request<{resultRecords : IPstProgressCompleteDto[]}>({
        method: 'get',
        url: URIs.get_pst_results_list,
      })).resultRecords;
    } catch (e) {
      console.error(e);
    }
  }

  @action
  saveSubmitApply = async (positionSn: number, optionSn: number, applySn: number) => {
    try {
      await request({
        method: 'put',
        url: URIs.put_application_submit_apply(positionSn),
        params: { optionSn, applySn },
      });
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  @action
  loadMessageList = async (positionSn: number) => {
    try {
      this.messagesDto = await request<IMatchingMessageListRs>({
        method: 'get',
        url: URIs.get_match_messages_list,
        params: { positionSn },
      });
      return true;
    } catch (e) {
      console.error(e);
      this.messagesDto = null;
    }
  }

  @action
  loadMessage = async (messageSn: number) => {
    try {
      this.message = await request({
        method: 'get',
        url: URIs.get_match_messages(messageSn),
      });
      return true;
    } catch (e) {
      console.error(e);
      this.message = null;
    }
  }

  @action
  AcceptInterview = async (positionSn: number, optionSn: number) => {
    try {
      const res = await request({
        method: 'put',
        url: URIs.put_match_interview_accept(positionSn),
        params: { optionSn },
      });
      return res;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  @action
  RefuseInterview = async (rq:IMatchingRejectRq) => {
    try {
      const res = await request({
        method: 'put',
        url: URIs.put_match_interview_denied(rq.positionSn),
        data: rq,
      });
      return res;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  @action
  AdjustInterview = async (rq:IMatchAdjustRq, positionSn:number) => {
    try {
      const res = await request({
        method: 'put',
        url: URIs.put_match_interview_adjusted(positionSn),
        data: rq,
      });
      return res;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  @action
  SubmitFile = async (files: File[], positionSn: number, optionSn: number) => {
    try {
      const formData = new FormData();
      files.map((file) => (formData.append('files', file)));

      const res = await request({
        method: 'post',
        url: URIs.post_match_application_position_files(positionSn),
        data: formData,
        params: { optionSn },
      });
      return res;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  @action
  DownloadFile = async (positionSn: number, fileUid: string) => {
    try {
      const res = await request<ArrayBuffer>({
        method: 'get',
        url: URIs.get_match_application_position_files(positionSn),
        params: { fileUid },
        responseType: 'arraybuffer',
      });
      return res;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  @action
  rejectMatch = async (rejectRq: IMatchingRejectRq) => {
    try {
      await request({
        method: 'post',
        url: URIs.post_v2_match_reject,
        data: rejectRq,
      });
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  @action
  acceptMatch = async (positionSn: number, optionSn: number) => {
    try {
      await request({
        method: 'post',
        url: URIs.post_match_accept,
        data: { positionSn, optionSn },
      });
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  @action
  acceptMatchCBO = async (positionSn: number, optionSn: number) => {
    try {
      await request({
        method: 'post',
        url: URIs.post_match_accept_v2,
        data: { positionSn, optionSn },
      });
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}
