/* eslint-disable @typescript-eslint/no-shadow */
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import MatchHomeDashboardInfoModal from 'components/_v2/matchHome/dashboard/MatchHomeDashboardInfoModal';
import MatchHomeDashboardList from 'components/_v2/matchHome/dashboard/MatchHomeDashboardList';
import MatchDashBoardMessageModal from 'components/jobMatch/dashboard/MatchDashBoardMessageModal';
import { MatchHomeDashboardPositionType, MatchHomeDashboardType } from 'consts/_v2/matchHome/dashboard/MatchHomeDashboardType';
import useQueryParams from 'hooks/useQueryParams';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import MatchMessageStore from 'models/MatchMessageStore';
import MatchHomeDashboardModel from 'models/_v2/MatchHomeDashboardModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import ImgBanner from 'assets/_v2/matchHome/img_passboard_event_banner.png';
import JDALink from 'components/JDALink';
import MatchHomeDashboardHiddenModal from 'components/_v2/matchHome/dashboard/MatchHomeDashboardHiddenModal';
import { Button, ButtonMode } from '__designkit__/button/Button';
import { MatchTabType } from 'consts/_v2/match/MatchType';
import { Divider1G40 } from 'components/divider/Divider';
import Shadows from '__designkit__/common/shadows';
import Icon from '__designkit__/icon/Icon';
import MyPageSnb from 'components/_v2/mypage/MyPageSnb';
import MyPageApplyPdfModal from 'components/_v2/mypage/MyPageApplyPdfModal';
import { MessageAccessPath } from 'interfaces/_v2/log/ILogRqRs';

const TabFrame = styled.div`
    display: flex;
    justify-content: space-between;
    padding:12px 16px;
    width: 100%;
`;
const BannerFrame = styled.img`
  width:100%;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
  margin-top: 8px;
`;
const StyledSNB = styled.div`
  position: fixed;
  bottom: calc(81px + env(safe-area-inset-bottom));
  right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 48px;
  background-color: ${Colors.JOBDA_BLACK};
  filter: ${Shadows.Shadow_300};
  .icon { 
    transition: transform 1s ease-in-out;
  }

  &.open .icon { 
    transform: rotate(45deg);
  }
`;

const TabEach = styled.div<{selected?:boolean}>`
    display: flex;
    background-color: ${(props) => (props.selected ? `${Colors.CG_30}` : `${Colors.WHITE_100}`)};
    border-radius:4px;
    justify-content:center;
    align-items:center;
    width:100%;
    padding: 13px 0;
    text-align:center;
    .text-frame{
        display:flex;
        flex-direction:column;
        .cnt-number{
            font:${Fonts.H5_Bold};
            color:${(props) => (props.selected ? `${Colors.JOBDA_BLACK}` : `${Colors.CG_60}`)};
        }
        .title-text{
            font:${Fonts.B4_Medium};
            color:${(props) => (props.selected ? `${Colors.JOBDA_BLACK}` : `${Colors.CG_60}`)};
        }    
    }
`;
const Frame = styled.div`
    padding:0 16px 40px 16px;
`;

const ButtonFrame = styled.div`
  padding: 12px 16px;
`;
interface IMypageApplyTab {
    login?: Login;
    matchHomeDashboardModel?: MatchHomeDashboardModel;
    matchMessageStore?: MatchMessageStore;
  }

const MypageApplyTab:FC<IMypageApplyTab> = ({ login = new Login(), matchMessageStore = new MatchMessageStore(), matchHomeDashboardModel = new MatchHomeDashboardModel() }) => {
  const [currentTab, setCurrentTab] = useState(MatchTabType.ALL);
  const { sn, tab } = useQueryParams();
  const [hiddenModalOpen, setHiddenModalOpen] = useState<boolean>(false);
  const [applyPdfModalOpen, setApplyPdfModalOpen] = useState<boolean>(false);
  const [isOpenSNB, setIsOpenSNB] = useState<boolean>(false);
  const { applicationCount } = matchHomeDashboardModel;
  const { resumes } = matchHomeDashboardModel;

  useEffect(() => {
    const init = async () => {
      await matchHomeDashboardModel.loadApplicationCount();
      await matchHomeDashboardModel.loadApplicationStatus();
      await matchHomeDashboardModel.loadHiddenPositions();
      if (!tab) {
        await matchHomeDashboardModel.loadApplicationHistories(MatchTabType.ALL);
      } else {
        await matchHomeDashboardModel.loadApplicationHistories(tab as MatchTabType);
        setCurrentTab(tab as MatchTabType);
      }
    };
    init();
  }, [login, tab]);

  useEffect(() => {
    const snTabInit = async () => {
      matchMessageStore.messageAccessPath = MessageAccessPath.MATCHING_CARD;
      if (sn) matchMessageStore.open(Number(sn));
    };
    snTabInit();
  }, [sn, tab]);

  const tabList = [{
    type: MatchTabType.ALL,
    count: applicationCount?.totalCount || 0,
    label: '전체',
  },
  {
    type: MatchTabType.APPLICATION,
    count: applicationCount?.applicationCompleteCount || 0,
    label: '지원 완료',
  },
  {
    type: MatchTabType.IN_PROGRESS,
    count: applicationCount?.inProgressCount || 0,
    label: '전형 진행중',
  },
  {
    type: MatchTabType.END,
    count: applicationCount?.processEndCount || 0,
    label: '전형 종료',

  }];
  const handleTabClick = async (tabType: MatchTabType) => {
    await matchHomeDashboardModel.loadApplicationHistories(tabType);
    setCurrentTab(tabType);
  };
  return (
    <>
      <TabFrame>
        {tabList.map((tab) => (
          <TabEach onClick={() => handleTabClick(tab.type)} selected={currentTab === tab.type} key={tab.type}>
            <div className='text-frame'>
              <div className='cnt-number'>{tab.count}</div>
              <div className='title-text'>{tab.label}</div>
            </div>
          </TabEach>
        ))}
      </TabFrame>
      <Divider1G40 />
      <div className='dashboard-frame'>
        {(currentTab === MatchTabType.ALL) && resumes && <MatchHomeDashboardList list={resumes.jobApplicationProcesses} type={MatchTabType.ALL} />}
        {(currentTab === MatchTabType.APPLICATION) && resumes && <MatchHomeDashboardList list={resumes.jobApplicationProcesses} type={MatchTabType.APPLICATION} />}
        {(currentTab === MatchTabType.IN_PROGRESS) && resumes && <MatchHomeDashboardList list={resumes.jobApplicationProcesses} type={MatchTabType.IN_PROGRESS} />}
        {(currentTab === MatchTabType.END) && resumes && <MatchHomeDashboardList list={resumes.jobApplicationProcesses} type={MatchTabType.END} />}
      </div>
      <StyledSNB onClick={() => setIsOpenSNB((prevState) => !prevState)}>
        <Icon
          name={isOpenSNB ? 'close' : 'add'}
          size={24}
          color={Colors.WHITE_100}
          className='icon'
        />
      </StyledSNB>
      <MatchHomeDashboardHiddenModal isOpen={hiddenModalOpen} onClickClose={() => setHiddenModalOpen(false)} />
      <MyPageApplyPdfModal isOpen={applyPdfModalOpen} onClickClose={() => setApplyPdfModalOpen(false)} />
      <MatchDashBoardMessageModal />
      <MatchHomeDashboardInfoModal
        isOpen={matchHomeDashboardModel.infoModal.isOpen}
        onClickClose={() => {
          matchHomeDashboardModel.infoModal.isOpen = false;
        }}
      />
      <MyPageSnb isOpen={isOpenSNB} onClickClose={() => setIsOpenSNB(false)} setApplyPdfModalOpen={setApplyPdfModalOpen} setHiddenModalOpen={setHiddenModalOpen} />
    </>
  );
};

export default inject(injectStore.login, injectStore.matchMessageStore, injectStore.matchHomeDashboardModel)(observer(MypageApplyTab));
