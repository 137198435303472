/* eslint-disable import/prefer-default-export */
export enum NotificationLogType {
    MATCH_OFFER = 'MATCH_OFFER'
}

export enum MediumType {
    PUSH = 'PUSH',
    KAKAOTALK = 'KAKAOTALK',
    SMS = 'SMS',
    EMAIL = 'EMAIL',
    APP = 'APP',
    PC_BROWSER = 'PC_BROWSER',
    MOBILE_BROWSER = 'MOBILE_BROWSER',
    NONE = 'NONE',
    UNKNOWN = 'UNKNOWN',

}
export enum MessageAccessPath{
    AUTO = 'AUTO',
    POSITION = 'POSITION',
    JOBDA_NOTIFICATION = 'JOBDA_NOTIFICATION',
    MATCHING_CARD = 'MATCHING_CARD',
    NONE = 'NONE',
}
export interface IPushNotificationSendLogSaveRq{
    matchingTalkMessageSn:number;
    notificationLogType:NotificationLogType
}

export interface INotificationViewLogSaveRq{
    positionSn:number;
    userId:string;
    mediumType:MediumType;
    messageAccesspath:MessageAccessPath
    notificationLogType:NotificationLogType
}
