import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import IconEvaluation from 'assets/_v2/_common/icon_evaluation.svg';
import IconAccResults from 'assets/_v2/acc/icon_acc_results.png';
import IconPhsResults from 'assets/_v2/phs/icon_phs_results.svg';
import DashboardResumeAgreeModal from 'components/_v2/_common/modals/DashboardResumeAgreeModal';
import AccResultModal from 'components/_v2/acc/AccResultModal';
import PstResultModal from 'components/_v2/phs/pst/PstResultModal';
import JDResumeViewModal from 'components/_v2/profile/JDResumeViewModal';
import ProfilePreviewModal from 'components/_v2/profile/preview/ProfilePreviewModal';
import MatchResumeChangeModal from 'components/jobMatch/dashboard/MatchResumeChangeModal';
import ErrorCode from 'consts/ErrorCodes';
import { MatchingCommandType, PhsAccType } from 'consts/MatchingMessageType';
import RoutePaths from 'consts/RoutePaths';
import useToast from 'hooks/useToast';
import { IAccProgressCompleteDto } from 'interfaces/_v2/acc/IAccProgressCompleteRs';
import { IMatchingTalkApplyMessageDto, IMatchingTalkMessageOptionDto } from 'interfaces/rqrs/IMatchingMessageListRs';
import { inject, observer } from 'mobx-react';
import MatchMessageStore from 'models/MatchMessageStore';
import AccResultsModel from 'models/_v2/AccResultsModel';
import MatchReportModel from 'models/_v2/MatchReportModel';
import PositionJdModel from 'models/_v2/PositionJdModel';
import ResumeModel from 'models/_v2/ResumeModel';
import ProfileModel from 'models/_v2/profile/ProfileModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import AuthorizeUtil from 'utils/AuthorizeUtil';
import { overDateNow } from 'utils/DateUtils';
import { windowOpenUtils } from 'utils/_v2/windowOpenUtils';

const UserMessageFrame = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 16px;
    .date-time{
        font: ${Fonts.B3_Medium};
        color: ${colors.cA4A4A4};
        margin-bottom: 8px;
    }

    .user-message-frame {
      width: 335px;
      padding: 12px 16px;
      word-break: break-all;
      font: ${Fonts.B1_Bold_P};
      color: ${colors.WHITE_100};
      background: ${colors.G_200};
      border-radius: 12px 2px 12px 12px;
    }

    .desc {
      display: flex;
      margin-top: 4px;
      color: ${colors.WHITE_100};
      font: ${Fonts.B2_Medium_P};

      >span {
        padding-top: 2px;
        margin-right: 4px;
      }
    }
    .small-divider{
      width: 291px;
      height: 1px;
      background-color: ${colors.G_100};
      margin:12px 0;
    }
    .separate-frame{
      display: flex;
      gap:12px;
      .title{
        font:${Fonts.B2_Bold_P};
        color:${colors.WHITE_100};
      }
    }
`;

const SubmitFrame = styled.button<{disabled?:boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 8px 16px;
  background:${(props) => (props.disabled ? `${colors.CG_40}` : `${colors.WHITE_100}`)} ;
  border:${(props) => (props.disabled ? `` : ` 1px solid ${colors.CG_40}`)} ;;
  border-radius: 4px;
  margin-top: 12px;

  img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
  
  span {
    font: ${Fonts.B1_Bold};
    color: ${(props) => (props.disabled ? `${colors.CG_60}` : `${colors.CG_80}`)} ;
  }
  
`;

interface IMatchDashBoardUserMessageProps {
  date: string;
  title: string;
  submitApplies?: IMatchingTalkApplyMessageDto[];
  openYn?: boolean;
  deleteYn?: boolean;
  command?: MatchingCommandType;
  accResultsModel?: AccResultsModel;
  positionJdModel?:PositionJdModel;
  positionSn: number;
  resumeModel?: ResumeModel;
  profileModel?: ProfileModel;
  sendDateTime: string;
  message?:string
  matchReportModel?:MatchReportModel
  decision?:IMatchingTalkMessageOptionDto
  matchMessageStore?:MatchMessageStore
}

const MatchDashBoardUserMessage: FC<IMatchDashBoardUserMessageProps> = ({ decision, openYn, message, sendDateTime, positionSn, date, title, submitApplies, deleteYn, command, positionJdModel = new PositionJdModel(), accResultsModel = new AccResultsModel(), resumeModel = new ResumeModel(), profileModel = new ProfileModel(), matchReportModel = new MatchReportModel(), matchMessageStore = new MatchMessageStore() }) => {
  const [isOpenAccResultModal, setIsOpenAccResultModal] = useState<boolean>(false);
  const [isOpenPstResultModal, setIsOpenPstResultModal] = useState<boolean>(false);
  const [isOpenResumeAgreeModal, setIsOpenResumeAgreeModal] = useState<boolean>(false);
  const [isOpenJDResumeViewModal, setIsOpenJDResumeViewModal] = useState<boolean>(false);
  const [isOpenProfileResumeViewModal, setIsOpenProfileResumeViewModal] = useState<boolean>(false);
  const [isOpenResumeChangeViewModal, setIsOpenResumeChangeViewModal] = useState<boolean>(false);
  const [selectedResultSn, setSelectedResultSn] = useState<number>(0);
  const [selectedResultItem, setSelectedResultItem] = useState<IAccProgressCompleteDto | undefined>(undefined);
  const [selectedResultType, setSelectedResultType] = useState<PhsAccType>(PhsAccType.ACC);
  const [matchBtnAbled, setMatchBtnAbled] = useState<boolean>(false);
  const { setToastObject } = useToast();
  const history = useHistory();
  const handleClick = async (data: IMatchingTalkApplyMessageDto) => {
    if (data.applyType === PhsAccType.PST) setIsOpenPstResultModal(true);
    else {
      await accResultsModel.loadAccProgressList();
      const accResultItem = accResultsModel.accProgressList?.find((accResult) => accResult.sn === data.applySn);
      setSelectedResultItem(accResultItem);
      setIsOpenAccResultModal(true);
    }
    setSelectedResultSn(data.applySn);
    setSelectedResultType(data.applyType);
  };
  useEffect(() => {
    const init = async () => {
      if (Number.isNaN(Number(positionSn))) {
        throw new Error();
      } else {
        // await positionJdModel.init(Number(positionSn));
      }
      if (matchReportModel.matchReportCompaniesPositions) {
        matchReportModel.matchReportCompaniesPositions.positions.forEach((position) => {
          if (position.sn === positionSn) {
            setMatchBtnAbled(true);
          }
        });
      }
    };
    init();
  }, [positionSn, matchReportModel]);

  const openAtsResume = async () => {
    // 5.2.0 프로필 지원하기로 플로우 변경
    setIsOpenResumeChangeViewModal(true);

    // 임시처리 (지커마 혼용)
    // await positionJdModel.loadPositionDetail(positionJdModel.positionSn);
    // if (deleteYn) setToastObject({ isOpen: true, type: 'ERROR', message: '포지션이 삭제되었습니다.' });
    // else if (!openYn) setToastObject({ isOpen: true, type: 'ERROR', message: '포지션이 종료되었습니다.' });
    // else if (closeYn) setToastObject({ isOpen: true, type: 'ERROR', message: '포지션이 마감되었습니다.' });
    // else if (positionJdModel.basicInfo && positionJdModel.basicInfo.openingDateTime && !(overDateNow(positionJdModel.basicInfo.openingDateTime)))setToastObject({ isOpen: true, type: 'INFO', message: '지원하신 포지션의 시작일이 변경됐습니다.', subMessage: '해당 날짜 이후에 확인하실 수 있습니다.' });
    // else {
    //   const accessToken = await AuthorizeUtil.getAccessToken();
    //   if (accessToken === null) throw new Error('accessToken is null');
    //   await resumeModel.getPublicKey(positionSn);
    //   const url = `${resumeModel.url}/writeResume?jobdaToken=Bearer ${accessToken}&publicKey=${resumeModel.publicKey}&positionId=${resumeModel.positionId}`;
    //   windowOpenUtils(url, 'writeResume');
    // }
  };

  const openPreviewResume = async () => {
    try {
      const agreeYn = await resumeModel.getResumeAgree(positionSn);
      if (!agreeYn) {
        // 2-1. 동의가 안되어 있다면 동의 모달 open (단, 동의가 안되어있는데 포지션이 삭제,종료,마감 되었으면 제출 지원서를 확인할 수 없다.)
        setIsOpenResumeAgreeModal(true);
      } else {
        // 2-2. 동의가 되어 있다면 제출 지원서 모달 open
        openSubmittedResume();
      }
    } catch (e: any) {
      if (e.response.data.errorCode === ErrorCode.B901) {
        setToastObject({ isOpen: true, type: 'ERROR', message: '채용 전형이 종료되어 삭제된 지원서입니다.' });
      }
    }
  };

  // 지원했던 프로필 미리보기
  const openPreviewProfileResume = async () => {
    try {
      const profileResumeSn = await profileModel.getProfileResumeSn(positionSn);
      if (profileResumeSn) {
        await profileModel.getProfileResumeDetail(Number(profileResumeSn));
        setIsOpenProfileResumeViewModal(true);
      } else {
        setToastObject({ isOpen: true, type: 'ERROR', message: '채용 전형이 종료되어 삭제된 지원서입니다.' });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const openSubmittedResume = async () => {
    await resumeModel.getResumeSn(positionSn);
    setIsOpenJDResumeViewModal(true);
  };

  // 제안 받은 프로필 미리보기 (매칭 프로필)
  const openPreviewMatchingProfile = async () => {
    await profileModel.getMatchOfferProfileResumeDetail(positionSn);
    setIsOpenProfileResumeViewModal(true);
  };

  const openMatchingReport = async () => {
    if (matchBtnAbled) {
      history.push(`${RoutePaths.company_details(matchMessageStore.messagesDto?.companySn || -1)}?positionSn=${positionSn}`);
      matchMessageStore.close();
    }
  };

  return (
    <>
      <UserMessageFrame>
        <p className='date-time'>{date}</p>
        <div className='user-message-frame'>
          {title}
          { submitApplies && submitApplies.length > 0 && command !== MatchingCommandType.ACC_LITE_REQUEST && (
            submitApplies.map((data) => (
              <SubmitFrame key={data.code} disabled={deleteYn} onClick={() => handleClick(data)}>
                { data.applyType !== PhsAccType.PST && <img src={IconAccResults} alt='icon-result' />}
                { data.applyType === PhsAccType.PST && <img src={IconPhsResults} alt='icon-result' />}
                <span>제출 결과표 확인하기</span>
              </SubmitFrame>
            ))
          )}
          {command && command === MatchingCommandType.WRITING_RESUME && (
            <SubmitFrame onClick={() => openAtsResume()}>
              <img src={IconEvaluation} alt='icon-evaluation' />
              <span>작성 중인 지원서 확인하기</span>
            </SubmitFrame>
          )}
          {command && command === MatchingCommandType.SHOW_SUBMIT_RESUME && (
            <>
              <div className='desc'><span>ⓘ</span> 지원서 수정은 지원 취소 후에 가능합니다.<br />
                단, 지원 취소가 제한되는 상황이 있을 수 있습니다.
              </div>

              <SubmitFrame onClick={() => openPreviewResume()}>
                <img src={IconEvaluation} alt='icon-evaluation' />
                <span>제출 지원서 확인하기</span>
              </SubmitFrame>
            </>
          )}
          {command && command === MatchingCommandType.SHOW_SUBMIT_PROFILE_RESUME && (
          <>
            <div className='desc'><span>ⓘ</span> 알려드립니다.<br />
              지원서 수정은 지원 취소 후에 가능합니다. <br />
              전형이 진행된 경우나 지원 마감시에는 취소가 불가능 합니다.
            </div>

            <SubmitFrame onClick={() => openPreviewProfileResume()}>
              <img src={IconEvaluation} alt='icon-evaluation' />
              <span>제출 지원서 확인하기</span>
            </SubmitFrame>
          </>
          )}
          {(command && command === MatchingCommandType.SHOW_MATCHING_PROFILE && (sendDateTime > '2023-11-30T10:00:00')) && (
          <>
            <div className='desc'><span>ⓘ</span> 알려드립니다.<br />
              수락한 제안은 다시 취소할 수 없습니다. <br />
              프로필 정보가 변경되었다면 인사담당자님께 문의해주시기 바랍니다.
            </div>

            <SubmitFrame onClick={() => openPreviewMatchingProfile()}>
              <img src={IconEvaluation} alt='icon-evaluation' />
              <span>제안받은 프로필 확인하기</span>
            </SubmitFrame>
          </>
          )}
          {(command && command === MatchingCommandType.SHOW_SUBMITTED_PROFILE && (sendDateTime > '2023-11-30T10:00:00')) && (
          <>
            <SubmitFrame onClick={() => openPreviewProfileResume()}>
              <img src={IconEvaluation} alt='icon-evaluation' />
              <span>제출한 프로필 확인하기</span>
            </SubmitFrame>
          </>
          )}
          {command && command === MatchingCommandType.SHOW_MATCHING_REPORT && (
          <>
            {message && <div className='desc'>{message}</div>}
            <div className='desc'><span>ⓘ</span> 알려드립니다.<br />
              매칭 수락 기한이 마감되었거나 제안을 거절한 리포트는 확인할 수 없습니다.
            </div>

            <SubmitFrame disabled={!matchBtnAbled} onClick={() => openMatchingReport()}>
              <Icon size={32} name='book-green' />
              <span>매칭 리포트 확인하기</span>
            </SubmitFrame>
          </>
          )}
          {command && command === MatchingCommandType.INTERVIEW_ACCEPTED && (
            <>
              <div className='desc'>수락한 제안은 취소할 수 없습니다.<br />일정 조율이 필요하다면 채용담당자님께 문의해 주세요.</div>
              <div className='small-divider' />
              {
               decision && decision.interviewLocation && (
               <div className='separate-frame'>
                 <div className='title'>장소</div>
                 <div className='title'>{decision.interviewLocation}</div>
               </div>
               )
              }
              {
  decision && decision.title !== '수락'
  && (
  <div className='separate-frame'>
    <div className='title'>시간</div>
    <div className='title'>{decision?.title}</div>
  </div>
  )
}

            </>
          )}
          {message && <div className='desc'>{message}</div>}

        </div>
      </UserMessageFrame>
      <AccResultModal
        isOpen={isOpenAccResultModal}
        onClose={async () => {
          await accResultsModel.reset();
          setIsOpenAccResultModal(false);
        }}
        sn={selectedResultSn}
        item={selectedResultItem}
        type={selectedResultType}
      />
      <PstResultModal
        isOpen={isOpenPstResultModal}
        onClose={() => setIsOpenPstResultModal(false)}
        sn={selectedResultSn}
        type={selectedResultType}
      />
      <DashboardResumeAgreeModal
        isOpen={isOpenResumeAgreeModal}
        onClickClose={() => setIsOpenResumeAgreeModal(false)}
        onSuccess={async () => {
          await resumeModel.postResumeAgree(positionSn);
          setIsOpenResumeAgreeModal(false);
          openSubmittedResume();
        }}
        positionSn={positionSn}
      />
      <JDResumeViewModal
        isOpen={isOpenJDResumeViewModal}
        onClickClose={() => setIsOpenJDResumeViewModal(false)}
        resumeSn={resumeModel.resumeSn}
      />
      {isOpenProfileResumeViewModal && profileModel.resumedProfileDetail && (
        <ProfilePreviewModal
          handleClose={() => setIsOpenProfileResumeViewModal(false)}
          data={profileModel.resumedProfileDetail}
        />
      )}
      <MatchResumeChangeModal
        isOpen={isOpenResumeChangeViewModal}
        onClickClose={() => setIsOpenResumeChangeViewModal(false)}
        positionSn={positionSn}
      />
    </>
  );
};

export default inject(injectStore.resumeModel, injectStore.matchReportModel, injectStore.accResultsModel, injectStore.matchMessageStore, injectStore.positionJdModel, injectStore.profileModel, injectStore.matchMessageStore)(observer(MatchDashBoardUserMessage));
