import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import Loading from 'components/Loading';
import { TOAST_TYPE } from 'components/_v2/_common/toast/JDToast';
import MessageAccPhsUnderModal from 'components/_v2/message/MessageAccPhsUnderModal';
import LogoImgOrText from 'components/common/LogoImgOrText';
import DashBoardIPhsSubmitButton from 'components/jobMatch/dashboard/DashBoardIPhsSubmitButton';
import MatchAcceptModal from 'components/jobMatch/dashboard/MatchAcceptModal';
import MatchDashBoardMatchOfferButton from 'components/jobMatch/dashboard/MatchDashBoardMatchOfferButton';
import MatchOfferDeniedModal from 'components/jobMatch/dashboard/MatchOfferDeniedModal';
import MatchAtsFailModal from 'components/modals/MatchAtsFailModal';
import MatchMessageAcceptModal from 'components/modals/MatchMessageAcceptModal';
import MatchMessageDeniedModal from 'components/modals/MatchMessageDeniedModal';
import ErrorCode from 'consts/ErrorCodes';
import { MatchingCommandType, MatchingMessageCommandType, MatchingMessageType, PhsAccType } from 'consts/MatchingMessageType';
import RoutePaths, { getInfoPreTestPreparationGuidePage } from 'consts/RoutePaths';
import SupportMail from 'consts/_v2/GlobalText';
import { lineClampOnlyOne } from 'consts/_v2/_common/style/mixins';
import useToast from 'hooks/useToast';
import { IMatchMessageProps } from 'interfaces/IMatchMessageProps';
import { IOnClickProps } from 'interfaces/matchMessage/IOnClickProps';
import { IMatchingTalkMessageDto, IMatchingTalkMessageOptionDto } from 'interfaces/rqrs/IMatchingMessageListRs';
import { inject, observer } from 'mobx-react';
import MatchMessageStore from 'models/MatchMessageStore';
import MatchApplyStatusModel from 'models/_v2/MatchApplyStatusModel';
import MatchReportModel from 'models/_v2/MatchReportModel';
import PositionJdModel from 'models/_v2/PositionJdModel';
import ProfileModel from 'models/_v2/profile/ProfileModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import MobileStore from 'store/mobileStore';
import styled from 'styled-components';
import { DateFormatYYMMDDUnit, DateFormatYYYYMMDDCCHHMMUnit, DateFormatYYYYMMDDHHMMCCWithDot, DateFormatYYYYMMDDHHMMWithDot, DateFormatYYYYMMDDWithDot, getFullTime, overDateNow } from 'utils/DateUtils';
import FileUtil from 'utils/FileUtil';
import LinkifyString from 'utils/_v2/TextLinkUtils';
import MatchDashboardNoScheduleButton from 'components/jobMatch/dashboard/MatchDashboardNoScheduleButton';
import JDALink from 'components/JDALink';
import { useAccApplyTrialUrl } from 'query/acc/useAccQuery';
import { windowOpenUtils } from 'utils/_v2/windowOpenUtils';
import MatchDashBoardFile from './MatchDashBoardFile';
import MatchDashBoardInterviewButton from './MatchDashBoardInterviewButton';
import MatchDashBoardOfferSchedule from './MatchDashBoardOfferSchedule';

/**
 * 매칭 메시지 - 회사 인담자 말풍선
 * 현재는 면접 제안 수락/거절만 가능 (21.12)
 */

const Frame = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
`;

const SenderFrame = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    .company-name {
        margin: 0 8px;
        font: ${Fonts.B2_Bold};
        color: ${colors.CG_90};
    }

    .send-time {
        font: ${Fonts.B3_Medium};
        color: ${colors.cA4A4A4};
    }

    .img-logo {
      border:0.75px solid ${colors.CG_40};
    }

    .thumbnail {
      border: 0.75px solid ${colors.G_200};
      font: ${Fonts.B3_Bold};
      line-height: 24px;
    }
`;

const MessageContent = styled.div<{following?:boolean}>`
    width:  340px;
    padding: 24px 24px;
    background: ${colors.CG_30};
    border-radius:${(props) => (props.following ? `12px` : `2px 12px 12px 12px`)};
    margin-top:${(props) => (props.following ? `8px` : '')};

    .title {
      font: ${Fonts.H5_Bold};
      color: ${colors.JOBDA_BLACK};
      margin-bottom: 12px;
    }

    .message {
      font: ${Fonts.B2_Medium_P};
      color: ${colors.CG_70};
      white-space: pre-line;
      

      .hyper-link {
        color: ${colors.B_90};
        user-select: text;
        text-decoration: underline;
      }
    }

    .hint {
        font: ${Fonts.B3_Medium_P};
        color: ${colors.CG_60};
        margin-top : 2px;
        white-space: pre-line;
        >b {
          font: ${Fonts.B3_Bold_P};
        }
      
      }

    .dueTime {
      font: ${Fonts.B2_Medium_P};
      color: ${colors.CG_80};
      margin-top: 16px;

      .bold {
          font:${Fonts.B2_Bold_P};
          color:${colors.CG_90};
          margin-right:12px;
        }
        .date {
          font:${Fonts.B2_Medium_P};
          color:${colors.CG_70};
        }
      
      a {
        font: ${Fonts.B3_Medium};
        color: ${colors.B_100};
        margin-bottom: 12px;
        
      }

      .hint-add {
        margin-top: 16px;

        div {
          margin-top: 12px;
          margin-bottom: 6px;
        }
      }
    }
    .acc-hint{
      display:flex;
      flex-direction:column;
      margin-top:16px;

    .title{
        font:${Fonts.B2_Bold_P};
        color:${colors.CG_90};
      }
      .box-frame{
        border: 1px solid ${colors.CG_40};
        border-radius:4px;
        display:flex;
        padding:14px 12px 14px 16px;
        align-items:center;
        justify-content:space-between;
        margin-bottom:24px;
        .text{
          font:${Fonts.B2_Medium_P};
          color:${colors.CG_70};  
          text-decoration:none;        
        }
    }
      

    }
    .decision-text {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 12px;
      width: 100%;
      height: 46px;
      border: 1px solid ${colors.CG_40};
      box-sizing: border-box;
      border-radius: 4px;
      background: ${colors.CG_40};
      font: ${Fonts.B2_Bold};
      color: ${colors.CG_60};
    }
`;

const SubmitFrame = styled.button<{disabled?:boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 8px 16px;
  background:${(props) => (props.disabled ? `${colors.CG_40}` : `${colors.WHITE_100}`)} ;
  border:${(props) => (props.disabled ? `` : ` 1px solid ${colors.CG_40}`)} ;;
  border-radius: 4px;
  margin-top: 12px;

  img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
  
  span {
    font: ${Fonts.B1_Bold};
    margin-left:8px;
    color: ${(props) => (props.disabled ? `${colors.CG_60}` : `${colors.CG_80}`)} ;
  }
  
`;

const DownloadBtn = styled.button`
width: 100%;
display: flex;
padding:14px 22px 14px 16px;
justify-content: flex-start;
align-items: center;
gap: 6px;
border: 1px solid ${colors.JOBDA_BLACK};
border-radius: 4px;
  .title-text{
    font:${Fonts.B2_Bold};
    color:${colors.JOBDA_BLACK};
    width: 200px;
    ${lineClampOnlyOne()}
    text-align: start;
  }
`;
type DecisionProps = {
  accept: IMatchingTalkMessageOptionDto | null;
  refuse: IMatchingTalkMessageOptionDto | null;
};

const getOptions = (options: IMatchingTalkMessageOptionDto[]) =>
// TODO: 다지선다 적용 시 고도화 필요

  // eslint-disable-next-line implicit-arrow-linebreak
  options.reduce(
    (prev, cur) => {
      const { command } = cur;
      switch (command) {
        case 'ACCEPT':
          prev.accept = cur;
          return prev;

        case 'REFUSE':
          prev.refuse = cur;
          return prev;

        default:
          return prev;
      }
    },
    {
      accept: null,
      refuse: null,
    } as DecisionProps,
  );

interface IMatchDashBoardCompanyMessageProps extends Omit<IMatchingTalkMessageDto, 'sender' | 'sendDateTime'> {
  positionSn: number;
  sendDateTime: string;
  companyName?: string;
  logoImageUrl?: string;
  onClick: (args: IOnClickProps) => void;
  loadMessage: () => void;
  openYn?: boolean;
  companySn?: number;
  matchApplyStatusModel?: MatchApplyStatusModel;
  profileModel?: ProfileModel;
  sendDateTimeFull: string;
  positionJdModel?:PositionJdModel;
  matchReportModel?:MatchReportModel
  sender?:string
  deleteYn?:boolean
}

export interface IDashBoardButtonProps {
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  disabled: boolean;
  completeYn?: boolean;
  scheduleTime?:string;
}

const MatchDashBoardCompanyMessage: FC<IMatchMessageProps & IMatchDashBoardCompanyMessageProps> = ({
  sn,
  positionSn,
  title,
  logoImageUrl,
  command,
  sendDateTime,
  sendDateTimeFull,
  decisionDateTime,
  companyName,
  messageType,
  interviewLocation,
  message,
  options = [],
  userAttachFiles,
  companyAttachFiles,
  decision,
  decisionDueDateTime,
  loadMessage,
  onClick,
  submitApplies,
  openYn,
  sender,
  companySn,
  matchMessageStore = new MatchMessageStore(),
  matchApplyStatusModel = new MatchApplyStatusModel(),
  positionJdModel = new PositionJdModel(),
  matchReportModel = new MatchReportModel(),
  profileModel = new ProfileModel(),
  deleteYn = false,

}) => {
  const useformed = useForm({ mode: 'all' });
  const history = useHistory();
  const { watch, control } = useformed;
  const [selection] = useState<DecisionProps>(getOptions(options));
  const selectOptions = useMemo(() => selection, [selection]);
  const [isDeniedModalVisible, setDeniedModalVisible] = useState(false);
  const [isAcceptMatchOfferModalVisible, setAcceptMatchOfferModalVisible] = useState(false);
  const [isDeniedMatchOfferModalVisible, setDeniedMatchOfferModalVisible] = useState(false);
  const [isAcceptModalVisible, setAcceptModalVisible] = useState(false);
  const [isPhsUnderOpen, setIsPhsUnderOpen] = useState<boolean>(false);
  const [selectOptionSn, setSelectOptionSn] = useState<number>(0);
  const [isPhsAccSubmitType, setIsPhsAccSubmitType] = useState<PhsAccType>(PhsAccType.ACC);
  const [isOpenProfileResumeViewModal, setIsOpenProfileResumeViewModal] = useState<boolean>(false);
  const [atsFailModalOpen, setAtsFailModalOpen] = useState<boolean>(false);
  const [InterViewOptionSn, setInterViewOptionSn] = useState<number>(0);
  const [scheduleTime, setScheduleTime] = useState<string>('');
  const [matchBtnAbled, setMatchBtnAbled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { data: accTutorialUrl } = useAccApplyTrialUrl();

  const { setToastObject } = useToast();
  useEffect(() => {
    const init = async () => {
      if (Number.isNaN(Number(positionSn))) {
        throw new Error();
      } else {
        // await positionJdModel.init(Number(positionSn));
      }
      if (matchReportModel.matchReportCompaniesPositions) {
        matchReportModel.matchReportCompaniesPositions.positions.forEach((position) => {
          if (position.sn === positionSn) {
            setMatchBtnAbled(true);
          }
        });
      }
    };
    init();
  }, [positionSn, matchReportModel.matchReportCompaniesPositions]);
  const handleClick = (async (e: React.MouseEvent<HTMLElement>) => {
    if (!openYn) {
      setToastObject({ isOpen: true, type: TOAST_TYPE.ERROR, message: '종료된 포지션입니다.', duration: 1500 });
      return;
    }

    const type: string = (e.target as HTMLElement).id;
    switch (type) {
      case 'accept':
        setAcceptModalVisible(true);
        break;
      case 'refuse':
        setDeniedModalVisible(true);
        break;
      case 'acceptModalClick':
        if (command === MatchingCommandType.INTERVIEW_OFFER_SCHEDULE && InterViewOptionSn) {
          onClick({ type: 'agree', optionSn: Number(InterViewOptionSn), positionSn });
        } else if (selectOptions.accept) {
          onClick({ type: 'agree', optionSn: selectOptions.accept.sn, positionSn });
        }
        setAcceptModalVisible(false);
        break;
      case 'refuseModalClick':
        if (selectOptions.refuse) {
          onClick({ type: 'disagree', optionSn: selectOptions.refuse.sn, positionSn });
        }
        setDeniedModalVisible(false);
        break;
      case 'acc':
        await matchMessageStore.loadAccProgressList();
        setIsPhsAccSubmitType(PhsAccType.ACC);
        handleOptionSn(PhsAccType.ACC);
        setIsPhsUnderOpen(true);
        break;
      case 'pacc':
        await matchMessageStore.loadPaccProgressList();
        setIsPhsAccSubmitType(PhsAccType.PACC);
        handleOptionSn(PhsAccType.PACC);
        setIsPhsUnderOpen(true);
        break;
      case 'pst':
        await matchMessageStore.loadPstProgressList();
        setIsPhsAccSubmitType(PhsAccType.PST);
        handleOptionSn(PhsAccType.PST);
        setIsPhsUnderOpen(true);
        break;
      case 'matchAccept':
        setAcceptMatchOfferModalVisible(true);
        break;
      case 'matchRefuse':
        setDeniedMatchOfferModalVisible(true);
        break;
      default:
    }
  });

  const viewPreviewProfile = async () => {
    try {
      await profileModel.getMatchOfferProfileResumeDetail(positionSn);
      setIsOpenProfileResumeViewModal(true);
    } catch (e) {
      setToastObject({ isOpen: true, type: TOAST_TYPE.ERROR, message: '오류가 발생했습니다. 다시 시도해 주세요.', duration: 1500 });
    }
  };

  const onChangeInterviewTime = (timeContent: string) => {
    setScheduleTime(timeContent);
  };
  const getDecisionMessage = (messageCommand: MatchingCommandType, decisionCommand: string) => {
    switch (decisionCommand) {
      case MatchingMessageCommandType.ACCEPT:
        return messageCommand === MatchingCommandType.MATCH_OFFER ? '수락 완료' : '면접 제안 수락';
      case MatchingMessageCommandType.REFUSE:
        return messageCommand === MatchingCommandType.MATCH_OFFER ? '거절 완료' : '면접 제안 거절';
      case MatchingMessageCommandType.SUBMISSION:
        return '파일 제출 완료';
      case MatchingMessageCommandType.ADJUST:
        return '면접 조율 신청';
      default:
        return null;
    }
  };

  const handleOptionSn = (type: PhsAccType) => {
    if (command === MatchingCommandType.ACC_REQUEST && options) setSelectOptionSn(options[0].sn);
    if (command === MatchingCommandType.PHS_REQUEST && options) {
      if (type === PhsAccType.PACC) setSelectOptionSn(options[0].sn);
      if (type === PhsAccType.PST) setSelectOptionSn(options[1].sn);
    }
  };
  const acceptMatching = async () => {
    try {
      if (sender === 'CBO') {
        await matchMessageStore.acceptMatchCBO(positionSn, selectOptions.accept ? selectOptions.accept.sn : -1);
      } else {
        await matchMessageStore.acceptMatch(positionSn, selectOptions.accept ? selectOptions.accept.sn : -1);
      }

      await matchApplyStatusModel.loadMatchList();
      await matchMessageStore.loadMessageList(positionSn);
      setToastObject({ isOpen: true, type: TOAST_TYPE.SUCCESS, message: '제안을 수락하셨습니다.', duration: 1500 });
    } catch (e: any) {
      switch (e.response?.data?.errorCode) {
        case ErrorCode.B901:
          setToastObject({ isOpen: true, type: TOAST_TYPE.ERROR, message: '종료된 포지션입니다.', duration: 1500 });
          break;
        case ErrorCode.B902:
          setToastObject({ isOpen: true, type: TOAST_TYPE.ERROR, message: '삭제된 포지션입니다.', duration: 1500 });
          break;
        case ErrorCode.B903:
          setToastObject({ isOpen: true, type: TOAST_TYPE.ERROR, message: '매칭 제안 이력이 없습니다.', duration: 1500 });
          break;
        case ErrorCode.B904:
          setToastObject({ isOpen: true, type: TOAST_TYPE.ERROR, message: '이미 수락한 제안입니다.', duration: 1500 });
          break;
        case ErrorCode.B905:
          setToastObject({ isOpen: true, type: TOAST_TYPE.ERROR, message: '존재하지 않는 선택지입니다.', duration: 1500 });
          break;
        case ErrorCode.B906:
          setToastObject({ isOpen: true, type: TOAST_TYPE.ERROR, message: '응답 마감 기한이 지났습니다.', duration: 1500 });
          break;
        case ErrorCode.B908:
          setToastObject({ isOpen: true, type: TOAST_TYPE.ERROR, message: '현재 서비스가 원활하지 않습니다. 잡다 헬프데스크로 문의 해 주세요.', subMessage: `잡다 헬프데스크 : ${SupportMail}`, duration: 1500 });
          break;
        default:
          setToastObject({ isOpen: true, type: TOAST_TYPE.ERROR, message: '지원에 실패했습니다. 다시 시도해 주세요.', duration: 1500 });
      }
    }
    setAcceptMatchOfferModalVisible(false);
  };
  const openMatchingReport = async () => {
    console.log('here?companySn');
    if (matchBtnAbled) {
      history.push(`${RoutePaths.company_details(matchMessageStore.messagesDto?.companySn || -1)}?positionSn=${positionSn}`);
      matchMessageStore.close();
    }
  };

  const handleDownload = async (fileUid: string, fileName: string, index: number) => {
    try {
      let fileArrayBuffer: ArrayBuffer = new ArrayBuffer(0);
      fileArrayBuffer = await matchMessageStore.DownloadFile(matchMessageStore.positionSn, fileUid);
      if (fileArrayBuffer)
        if (MobileStore.isMobile) {
          setIsLoading(true);
        }
      FileUtil.fileDownload(fileName, fileArrayBuffer, false, setToastObject, setIsLoading);
    } catch (e:any) {
      if (e.response.data.errorCode === 'B908') {
        setAtsFailModalOpen(true);
      } else {
        // ATS 통신 에러 일괄 처리
        setAtsFailModalOpen(true);
      }
      console.error(e);
    }
  };

  const openAccTutorial = () => {
    if (accTutorialUrl) {
      windowOpenUtils(accTutorialUrl.url, '_self');
    }
  };
  return (
    <FormProvider {...useformed}>
      <Frame>
        {isLoading && <Loading />}

        <SenderFrame>
          <LogoImgOrText imgUrl={logoImageUrl} text={companyName} size={24} />
          <span className='company-name'>{companyName} 담당자</span>
          <span className='send-time'>{sendDateTime}</span>
        </SenderFrame>
        <MessageContent>
          <p className='title'>{title}</p>
          <p className='message'><LinkifyString text={message} /></p>

          {companyAttachFiles && companyAttachFiles.length > 0 && (
            companyAttachFiles.map((file, index) => (
              <>
                {index === 0 && <SpacingBlock vertical size={24} />}
                <DownloadBtn key={file.fileName} onClick={() => handleDownload(file.fileUid, file.fileName, index)}>
                  <Icon name='download' size={24} color={colors.JOBDA_BLACK} />
                  <span className='title-text'>{file.fileName}</span>
                </DownloadBtn>
                {index !== companyAttachFiles.length - 1 && <SpacingBlock vertical size={8} />}
              </>
            ))
          )}
          {(command === MatchingCommandType.MATCH_OFFER || command === MatchingCommandType.INTERVIEW_OFFER) && decisionDueDateTime && (
            <div className='hint'>
              <b>응답 기한 : {DateFormatYYYYMMDDWithDot(decisionDueDateTime)}</b><br />
              * 정해진 기한까지 응답하지 않을 경우 수락이 불가능합니다.
            </div>
          )}
          {messageType === MatchingMessageType.OPTIONS && decisionDueDateTime && (command === MatchingCommandType.ACC_REQUEST || command === MatchingCommandType.PHS_REQUEST || command === MatchingCommandType.ACC_LITE_REQUEST) && (
          <p className='dueTime'>
            <span className='bold'>
              { (command === MatchingCommandType.ACC_REQUEST || command === MatchingCommandType.PHS_REQUEST || command === MatchingCommandType.ACC_LITE_REQUEST) ? '제출' : '답변' } 기한
            </span>
            <span className='date'>
              {DateFormatYYYYMMDDCCHHMMUnit(decisionDueDateTime)}

            </span>
            <div className='hint'>
              {(command === MatchingCommandType.ACC_REQUEST || command === MatchingCommandType.PHS_REQUEST || command === MatchingCommandType.ACC_LITE_REQUEST) ? `정해진 기한까지 응답하지 않을 경우 제출이 불가능합니다.\n 반드시 제출 마감 전까지 역량검사 응시를 완료해 주세요.` : '정해진 유효기간까지 답변이 없을 경우 자동 불참 처리됩니다.'}
            </div>
            <div className='acc-hint'>
              <div className='title'>준비하기</div>

              <div role='button' onClick={openAccTutorial} className='box-frame'>
                <div className='text'>역량검사 응시 가이드</div>
                <Icon name='arrow-right' size={24} color={colors.CG_60} />
              </div>
            </div>
          </p>
          )}
          {/* 매칭 제안인 경우 */}
          {
            (command === MatchingCommandType.MATCH_OFFER && !decision)
              && (!overDateNow(decisionDueDateTime) ? (
                <MatchDashBoardMatchOfferButton
                  onClick={(e) => handleClick(e)}
                  disabled={!!decision}
                />
              )
                : (
                  <div className='decision-text'>응답 기한 만료</div>
                )
              )
}

          {(command === MatchingCommandType.FILE_REQUEST || command === MatchingCommandType.FILE_SUBMIT) && options
            && <MatchDashBoardFile decision={decision} decisionDueDateTime={decisionDateTime} loadMessage={loadMessage} messageSn={sn} companyAttachFiles={companyAttachFiles} command={command} optionSn={options[0].sn} />}

          {(
            command === MatchingCommandType.INTERVIEW_CANCEL
              ? <div className='decision-text'>면접 요청 만료</div>
              : (
                decision && command !== MatchingCommandType.INTERVIEW_OFFER_SCHEDULE && (
                <div className='decision-text'>
                  {getDecisionMessage(command, decision.command)}
                </div>
                )
              )
          )}
          {command && command === MatchingCommandType.SHOW_MATCHING_REPORT && (
          <>
            <SubmitFrame disabled={!matchBtnAbled} onClick={() => openMatchingReport()}>
              <Icon size={32} name='book-green' />
              <span>매칭 리포트 확인하기</span>
            </SubmitFrame>
          </>
          )}
          {(command === MatchingCommandType.ACC_REQUEST || command === MatchingCommandType.PHS_REQUEST || command === MatchingCommandType.ACC_LITE_REQUEST)
            && <DashBoardIPhsSubmitButton submitApplies={submitApplies} onClick={(e) => handleClick(e)} disabled={overDateNow(decisionDueDateTime) || !openYn || deleteYn} type={command} completeYn={!!decisionDateTime} />}

        </MessageContent>

        {command === MatchingCommandType.INTERVIEW_OFFER_SCHEDULE && (
          <MessageContent following>
            <MatchDashBoardOfferSchedule
              control={control}
              interviewLocation={interviewLocation}
              options={options}
              decision={decision}
              onChange={onChangeInterviewTime}
              decisionDueDateTime={decisionDueDateTime}
              positionSn={positionSn}
              setInterviewOptionSn={setInterViewOptionSn}
            />

            {!decision
            && command === (MatchingCommandType.INTERVIEW_OFFER_SCHEDULE)
            && messageType !== MatchingMessageType.SIMPLE && (
              <MatchDashBoardInterviewButton
                onClick={(e) => handleClick(e)}
                disabled={
                 !openYn! || decision
                }
                scheduleTime={scheduleTime}
              />
            )}
            {(
            decision && command === MatchingCommandType.INTERVIEW_OFFER_SCHEDULE && (
              <>
                <SpacingBlock vertical size={28} />
                <div className='decision-text'>
                  {getDecisionMessage(command, decision.command)}
                </div>
              </>
            )

          )}
          </MessageContent>
        )}
        {!decision && command === MatchingCommandType.INTERVIEW_OFFER && (
        <MessageContent following>
          {!decision
            && command === (MatchingCommandType.INTERVIEW_OFFER)
            && messageType !== MatchingMessageType.SIMPLE && (
              <MatchDashboardNoScheduleButton
                onClick={(e) => handleClick(e)}
                disabled={false}
              />
          )}
        </MessageContent>
        )}
        <MatchMessageAcceptModal
          isOpen={isAcceptModalVisible}
          schedule={command === MatchingCommandType.INTERVIEW_OFFER_SCHEDULE}
          scheduleLocation={interviewLocation && interviewLocation}
          scheduleTime={scheduleTime}
          onClickApply={(e) => handleClick(e)}
          onClickClose={() => setAcceptModalVisible(false)}
        />
        {
          isDeniedModalVisible && (
          <MatchMessageDeniedModal
            isOpen={isDeniedModalVisible}
            onClickApply={(e) => handleClick(e)}
            onClickClose={() => setDeniedModalVisible(false)}
            positionSn={positionSn}
            optionSn={selectOptions.refuse ? selectOptions.refuse.sn : -1}
          />
          )
        }

        <MatchAcceptModal
          isOpen={isAcceptMatchOfferModalVisible}
          onClickClose={() => setAcceptMatchOfferModalVisible(false)}
          onClickApply={acceptMatching}
          sendDateTime={sendDateTimeFull}
          onClickViewProfile={viewPreviewProfile}
          sender={sender}
        />
        {isDeniedMatchOfferModalVisible
          && (
          <MatchOfferDeniedModal
            isOpen={isDeniedMatchOfferModalVisible}
            onClickClose={() => setDeniedMatchOfferModalVisible(false)}
            positionSn={positionSn}
            optionSn={selectOptions.refuse ? selectOptions.refuse.sn : -1}
          />
          )}
        <MessageAccPhsUnderModal
          isOpen={isPhsUnderOpen}
          onClickClose={() => setIsPhsUnderOpen(false)}
          submitType={isPhsAccSubmitType}
          positionSn={positionSn}
          optionSn={selectOptionSn}
          companySn={companySn}
        />
        <MatchAtsFailModal
          isOpen={atsFailModalOpen}
          onClickClose={() => setAtsFailModalOpen(false)}
        />
      </Frame>
    </FormProvider>
  );
};

export default inject(injectStore.matchReportModel, injectStore.matchMessageStore, injectStore.positionJdModel, injectStore.matchApplyStatusModel)(observer(MatchDashBoardCompanyMessage));
