import iconCalendar from 'assets/icon/icon_calendar_16_Regular.svg';
import iconEye from 'assets/icon/icon_eyes_open_20.svg';
import LazyImage from 'components/common/LazyImage';
import React, { useState } from 'react';
import ShareButtonGroup from 'components/info/ShareButtonGroup';
import colors from '__designkit__/common/colors';
import { fonts } from '__designkit__/common/fonts';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import Info from 'models/Info';
import { IInfoDetailPageProps } from 'pages/InfoDetailPage';
import RoutePaths from 'consts/RoutePaths';
import BreadcrumbNavigation, { BreadcrumbItem } from 'components/_v2/_common/navigation/BreadcrumbNavigation';

const InfoFrame = styled.div`
  padding: 32px 24px;
`;

const InfoHeader = styled.header`
  flex: 1;
  flex-direction: column;
`;

const InfoHeaderTitle = styled.p`
  ${fonts.NOTO_24_700};
  color: ${colors.c474747};
  line-height: 36px;
  word-break: break-all;
  margin-top: 16px;
`;

const InfoHeaderOptionalInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
`;

const VerticalDivider = styled.div`
  width: 1px;
  height: 12px;
  margin: 0 12px;
  background-color: ${colors.CG_40};
`;

const HorizontalDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colors.CG_40};
  margin: 24px 0 32px;
`;

const IconWithTextFrame = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OptionalInfoText = styled.span`
  display: inline-block;
  ${fonts.NOTO_13_400};
  line-height: 16px;
  color: ${colors.c6A6A6A};
  margin-left: 6px;
`;

const InfoBody = styled.div`
  margin-bottom: 48px;
  overflow:auto;
  * {
    line-height:28px;
    font-weight: inherit;
    font-style: inherit;
    text-decoration: inherit;
  }
  
  
  .contents  b {
    font-weight: bold;
    font-size: inherit;
    
  }
  
  .contents u {
    text-decoration: underline;
    font-weight: inherit;
    font-size: inherit;
  }
  
  .contents i {
    font-style: oblique;
    font-weight: inherit;
    font-size: inherit;
  }
  
  .contents span {
    word-break: break-all;
  }
  
`;

const InfoDetailForm: React.FC<IInfoDetailPageProps> = ({ info = new Info() }) => {
  const [breadcrumbItems] = useState<BreadcrumbItem[]>([{ title: '취업 콘텐츠', url: RoutePaths.info }, { title: info.category, url: RoutePaths.info_category(info.categorySn) }]);

  return (
    <InfoFrame>
      <InfoHeader>
        <BreadcrumbNavigation items={breadcrumbItems} />
        <InfoHeaderTitle>{info.title}</InfoHeaderTitle>
        <InfoHeaderOptionalInfo>
          <IconWithTextFrame>
            <LazyImage src={iconCalendar} />
            <OptionalInfoText>{info.date}</OptionalInfoText>
          </IconWithTextFrame>
          <VerticalDivider />
          <IconWithTextFrame>
            <LazyImage src={iconEye} />
            <OptionalInfoText>{info.viewCount}</OptionalInfoText>
          </IconWithTextFrame>
        </InfoHeaderOptionalInfo>
        <HorizontalDivider />
        <InfoBody dangerouslySetInnerHTML={{ __html: info.contents }} />
        <ShareButtonGroup
          kakao={{
            title: info.title,
            description: info.title,
            mobileWebUrl: document.location.href,
            webUrl: document.location.href,
          }}
          facebook={{
            u: document.location.href,
            quote: info.title,
          }}
          webShare={{
            title: info.title,
            url: document.location.href,
            text: info.title,
          }}
        />
      </InfoHeader>
    </InfoFrame>
  );
};

export default inject(injectStore.info)(observer(InfoDetailForm));
